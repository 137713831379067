<template>
  <div class="flex flex-col p-5 bg-cyan-1000 text-white">
    <div class="pt-10 grid grid-cols-2 md:grid-cols-3 gap-4 self-center">
      <div>
        <div class="space-y-5 font-semibold">
          <router-link class="block focus:outline-none" to="/services"
            >Services</router-link
          >
          <!-- <router-link class="block" to="/clients">Clients</router-link> -->
          <router-link class="block focus:outline-none" to="/solutions"
            >Solutions</router-link
          >
          <router-link class="block focus:outline-none" to="/about-us"
            >About Us</router-link
          >
        </div>
      </div>
      <div>
        <div class="space-y-5 font-semibold">
          <div>
            <a href="https://www.linkedin.com/company/auraborus/" target="blank"
              ><font-awesome-icon class="text-xl" :icon="['fab', 'linkedin']" />
              LinkedIn</a
            >
          </div>
          <div>
            <a href="https://www.facebook.com/auraborusinc" target="blank"
              ><font-awesome-icon
                class="text-xl"
                :icon="['fab', 'facebook-square']"
              />
              Facebook</a
            >
          </div>
          <div>
            <a href="https://twitter.com/AuraborusInc" target="blank"
              ><font-awesome-icon
                class="text-xl"
                :icon="['fab', 'twitter-square']"
              />
              Twitter</a
            >
          </div>
        </div>
      </div>
      <div
        class="font-semibold space-y-3 col-span-2 sm:col-span-1 pt-3 border-t-2 sm:border-0"
      >
        <span>Email Us : admin@auraborus.com</span>
        <div class="text-sm gtid divide-x-2">
          <span class="pr-3">Contact : +923059394319</span>
          <span class="pl-3">+923340022810</span>
        </div>
      </div>
    </div>
    <div class="h-1/6 self-center mt-16 text-sm">
      &reg;Auraborus - 2021 All Rights Reserved.
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style></style>
