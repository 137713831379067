<template>
  <suspense>
    <template #default>
      <div
        class="subpixel-antialiased"
        :class="{ dark: this.$store.state.darkMode }"
      >
        <div class="dark:bg-cyan-1000">
          <Navbar class="sticky top-0 z-20" />
          <div class="transition-colors duration-500">
            <router-view v-slot="{ Component }" class="pb-12">
              <keep-alive>
                <component :is="Component" />
              </keep-alive>
            </router-view>
          </div>
          <div
            class="px-7 py-0.5 my-5 rounded-full w-2/5 sm:w-1/5 mx-auto bg-gray-600 dark:bg-white"
          ></div>
          <Footer />
        </div>
      </div>
    </template>

    <template #fallback>
      <div class="flex flex-col space-y-5 h-screen justify-center items-center">
        <svg
          :class="[
            'animate-spin',
            'h-7',
            'w-7',
            'bg-green-600',
            'dark:bg-white',
          ]"
          viewBox="0 0 24 24"
        ></svg>
        <span
          :class="[
            'animate-pulse',
            'text-gray-700',
            'dark:text-white',
            'font-bold',
          ]"
          >Loading</span
        >
      </div>
    </template>
  </suspense>
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
// import { defineAsyncComponent } from "vue"
// const Navbar = defineAsyncComponent({
//   loader: () => import("@/components/Navbar" /* webpackChunkName: "Navbar" */),
// })
export default {
  components: { Navbar, Footer },
};
</script>

<style>
</style>
