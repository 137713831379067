import { createStore } from 'vuex'

// Create a new store instance.
const store = createStore({
  state() {
    return {
      menuToggle: false,
      brightness: 100,
      // darkMode: false,
      darkMode: localStorage.getItem('mode') === 'true' || false,
    }
  },
  mutations: {
    toggleMenu(state) {
      state.menuToggle = !state.menuToggle;
    },
    setBrightness(state, brightness) {
      state.brightness = brightness;
    },
    setDarkMode(state, mode) {
      state.darkMode = mode;
      localStorage.setItem('mode', mode)
    }
  }
})

export default store;
