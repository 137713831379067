<template>
  <div class="grid relative mt-10 w-full">
    <div
      class="absolute self-center w-full h-5/6 rounded-3xl bg-gradient-to-br from-red-400 to-red-600"
    ></div>
    <div class="z-10 flex justify-center">
      <div class="w-5/6 bg-white rounded-xl p-10 shadow-2xl border-2">
        <form
          class="w-full grid grid-cols-1 sm:grid-cols-2 gap-4"
          v-on:submit="onSubmit"
        >
          <input
            type="text"
            v-model="formData.name"
            placeholder="Name"
            :class="[
              'p-3',
              'outline-none',
              'border-2',
              'border-gray-400',
              'focus:border-gray-600',
              'text-gray-800',
              'placeholder-gray-600',
              'bg-transparent',
            ]"
            required
          />
          <input
            type="email"
            v-model.trim="formData.email"
            placeholder="Email"
            :class="[
              'p-3',
              'outline-none',
              'border-2',
              'border-gray-400',
              'focus:border-gray-600',
              'text-gray-800',
              'placeholder-gray-600',
              'bg-transparent',
            ]"
            required
          />
          <input
            type="text"
            v-model="formData.phone"
            placeholder="Phone(Optional)"
            pattern="\+?\d+"
            :class="[
              'p-3',
              'outline-none',
              'border-2',
              'border-gray-400',
              'focus:border-gray-600',
              'text-gray-800',
              'placeholder-gray-600',
              'bg-transparent',
            ]"
          />
          <input
            type="text"
            v-model="formData.company"
            placeholder="Company/Organization Name(Optional)"
            :class="[
              'p-3',
              'outline-none',
              'border-2',
              'border-gray-400',
              'focus:border-gray-600',
              'text-gray-800',
              'placeholder-gray-600',
              'bg-transparent',
            ]"
          />
          <select
            v-model="formData.serviceType"
            :class="[
              'p-3',
              'appearance-none',
              'outline-none',
              'border-2',
              'border-gray-400',
              'focus:border-gray-600',
              'text-gray-800',
              'placeholder-gray-600',
              'bg-transparent',
            ]"
            required
          >
            <option disabled hidden value="">Select Service</option>
            <option value="Web Development">Web Development</option>
            <option value="Mobile Development">Mobile Development</option>
            <option value="Desktop Development">Desktop Development</option>
          </select>
          <div
            :class="[
              'flex',
              'relative',
              'border-2',
              'border-gray-400',
              'focus-within:border-gray-600',
            ]"
          >
            <div
              :class="[
                'flex',
                'absolute',
                'items-center',
                'justify-center',
                'text-lg',
                'text-gray-500',
                'font-bold',
                'w-12',
                'h-full',
              ]"
            >
              $
            </div>

            <input
              type="text"
              v-model="formData.budget"
              placeholder="Budget"
              :class="[
                'p-3',
                'z-10',
                'pl-12',
                'outline-none',
                'flex-grow',
                'text-gray-800',
                'placeholder-gray-600',
                'bg-transparent',
              ]"
              required
            />
          </div>
          <textarea
            v-model="formData.description"
            placeholder="Tell us about your project"
            :class="[
              'sm:col-span-2',
              'p-3',
              'outline-none',
              'border-2',
              'border-gray-400',
              'focus:border-gray-600',
              'text-gray-800',
              'placeholder-gray-600',
              'bg-transparent',
            ]"
            required
          />
          <div class="sm:col-span-2 text-center">
            <button
              :class="[
                'p-2',
                'px-10',
                'border-2',
                'rounded',
                'font-semibold',
                'focus:outline-none',
                'self-start',
                'text-gray-700',
                'border-gray-800',
                'hover:bg-gray-800',
                'hover:text-white',
                'transition-colors',
                'duration-500',
              ]"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
        <svg
          class="animate-spin mx-auto mt-6 h-5 w-5 bg-cyan-1000"
          :class="[loading ? 'opacity-100' : 'opacity-0']"
          viewBox="0 0 24 24"
        >
          <!-- ... -->
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import MiscService from "../services/MiscService";

export default {
  name: "ContactUsForm",
  props: {
    isWhite: {
      type: Boolean,
    },
  },
  data() {
    return {
      formData: {
        name: "",
        email: "",
        phone: "",
        company: "",
        serviceType: "",
        budget: "",
        description: "",
      },
      loading: false,
    };
  },
  methods: {
    onSubmit(e) {
      //loading -> start on submit click
      this.loading = true;
      e.preventDefault();
      console.log(this.formData);
      MiscService.contact(this.formData)
        .then(() => {
          //loading -> stop on request success
          this.loading = false;
          this.$router.push("/contact-us/success");
          // console.log(res);
        })
        .catch(() => {
          //loading -> stop on request fail
          this.loading = false;
          this.$router.push("/contact-us/error");
          // console.log(err);
        });
    },
  },
};
</script>

<style scoped>
:invalid {
  box-shadow: none;
}

:-moz-submit-invalid {
  box-shadow: none;
}

:-moz-ui-invalid {
  box-shadow: none;
}
</style>

// 'dark:focus:border-gray-200', // 'text-gray-800', // 'dark:text-white', //
'placeholder-gray-600', // 'dark:placeholder-white',
