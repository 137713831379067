import { createRouter, createWebHistory } from "vue-router";
import LandingPage from "@/views/LandingPage";

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: LandingPage,
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: () =>
      import("../views/AboutUs.vue" /* webpackChunkName: "aboutus" */),
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import("../views/ContactUs.vue" /* webpackChunkName: "Contact" */),
  },
  {
    path: "/contact-us/:response",
    name: "ContactResponse",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        "../views/ContactResponse.vue" /* webpackChunkName: "Contact Response" */
      ),
  },
  {
    path: "/solutions",
    name: "Solutions",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import("../views/Solutions.vue" /* webpackChunkName: "Solutions" */),
  },
  {
    path: "/services",
    name: "Services",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Services" */ "../views/Services.vue"),
  },
  {
    path: "/services/web",
    name: "WebService",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Services" */ "../views/WebService.vue"),
  },
  {
    path: "/services/mobile",
    name: "MobileService",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Services" */ "../views/MobileService.vue"),
  },
  {
    path: "/services/desktop",
    name: "DesktopService",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Services" */ "../views/DesktopService.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "404Page" */ "../views/Errors/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
