import request from './Request';

let contact = (formData) => {
    return request('post', 'contact', formData)
}


const MiscService = {
    contact
}

export default MiscService;