<template>
  <div
    class="px-10 sm:px-10 md:px-20 overflow-x-hidden text-gray-700 dark:text-white"
  >
    <div class="my-20">
      <!--Head Section-->
      <div class="text-3xl sm:text-4xl font-bold">We Make Your Ideas Fly</div>
      <div class="relative flex flex-row justify-between mt-7">
        <div
          class="bg-gray-300 dark:bg-cyan-600 sm:hidden rounded-md absolute h-80 w-52 -right-16 transform -rotate-12"
        ></div>
        <div
          class="bg-cyan-500 dark:bg-cyan-800 sm:hidden rounded-md absolute h-80 w-52 -right-16 transform rotate-45"
        ></div>
        <div class="z-10 sm:w-3/5">
          <div class="flex flex-col space-y-12 items-center py-7">
            <p
              class="text-3xl lg:text-5xl font-semibold sm:font-normal lg:leading-snug"
            >
              Building Digital Experiences & Opportunities For All.
            </p>
            <router-link
              to="/contact-us"
              :class="[
                'p-2',
                'px-10',
                'border-2',
                'rounded',
                'font-semibold',
                'focus:outline-none',
                'self-start',
                'border-gray-800',
                'hover:bg-gray-800',
                'hover:text-white',
                'dark:border-gray-300',
                'dark:hover:bg-white',
                'dark:hover:text-cyan-1000',
                'transition-colors',
                'duration-500',
              ]"
            >
              Let's Start A Conversation
            </router-link>
          </div>
        </div>
        <div class="w-0 sm:w-2/5 flex justify-end flex-shrink-0">
          <img src="../../public/landing-image.png" alt="Contact Image" />
          <!-- <img
            :class="[$store.state.darkMode && 'hidden', 'max-h-96']"
            src="../../public/logo-light.png"
            alt="Contact Image"
          />
          <img
            :class="[!$store.state.darkMode && 'hidden', 'max-h-96']"
            src="../../public/logo-dark.png"
            alt="Contact Image"
          /> -->
        </div>
      </div>

      <!-- Service Section -->
      <div class="text-3xl mt-24 sm:mt-0 my-5 font-bold">We Offer</div>
      <div class="grid relative mt-10">
        <div class="absolute bg-gray-200 w-full h-full rounded-3xl"></div>
        <div
          class="absolute w-full h-full rounded-3xl transform bg-gradient-to-br from-cyan-400 to-cyan-600 -rotate-1 sm:-rotate-2"
        ></div>
        <div class="p-8 sm:p-10 z-10 grid grid-cols-1 md:grid-cols-2 gap-4">
          <div
            class="flex flex-col rounded-xl shadow-xl bg-white dark:bg-cyan-1000 dark:text-gray-100"
          >
            <div class="p-7 sm:p-14">
              <div class="w-full sm:w-1/2 text-xl font-bold pb-5">
                Web App Development
              </div>
              <div>
                We bring the internet to your disposal with enriched, modern and
                powerful websites, tailored to your needs. Our websites are
                designed to be fast, highly responsive and trendy to engage
                users and bring business and traffic to you.
              </div>
            </div>
          </div>
          <div
            class="flex flex-col rounded-xl shadow-xl bg-white dark:bg-cyan-1000 dark:text-gray-100"
          >
            <div class="p-7 sm:p-14">
              <div class="w-full sm:w-1/2 text-xl font-bold pb-5">
                Mobile App Development
              </div>
              <div>
                Discover the world in your palm! We harness the power of
                portable computing and create modern and unique designs to
                provide consumers a brilliant and memorable user experience.
              </div>
            </div>
          </div>
          <div
            class="flex flex-col md:col-span-2 rounded-xl shadow-xl bg-white dark:bg-cyan-1000 dark:text-gray-100"
          >
            <div class="p-7 sm:p-14">
              <div class="w-full sm:w-1/2 text-xl font-bold pb-5">
                Desktop App Development
              </div>
              <div>
                Discover the world in your palm! We harness the power of
                portable computing and create modern and unique designs to
                provide consumers a brilliant and memorable user experience.
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Test Section -->
      <div class="text-3xl mt-20 font-bold">Contact Us</div>
      <ContactUsForm />
    </div>
  </div>
</template>

<script>
import ContactUsForm from "../components/ContactUsForm.vue";
export default {
  name: "LandingPage",
  components: { ContactUsForm },
};
</script>
    ContactUs

<style>
</style>
