import { createApp } from "vue";
import App from "./App.vue";
import "./assets/styles/tailwind.css";
import router from "./router";
import store from "../src/store/index.js";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCaretDown,
  faCaretRight,
  faRocket,
  faShieldAlt,
  faRulerCombined,
  faLaptop,
  faPaintBrush,
  faLaptopCode,
  faDesktop,
  faMobileAlt,
  faWindowMaximize,
  faCode,
  faEllipsisH,
  faTabletAlt,
  faCheck,
  faArrowLeft,
  faTimes,
  faPencilRuler,
  faLightbulb,
  faLifeRing,
  faHandshake,
  faFrown,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare as fabFacebookSquare,
  faTwitterSquare as fabTwitterSquare,
  faLinkedin as fabLinkedinIn,
  faAndroid,
  faApple,
  faLinux,
  faWindows,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faCaretDown,
  faCaretRight,
  faPencilRuler,
  faLightbulb,
  faLifeRing,
  faHandshake,
  fabFacebookSquare,
  fabTwitterSquare,
  fabLinkedinIn,
  faRocket,
  faShieldAlt,
  faRulerCombined,
  faLaptop,
  faAndroid,
  faApple,
  faPaintBrush,
  faLaptopCode,
  faDesktop,
  faMobileAlt,
  faTabletAlt,
  faWindowMaximize,
  faCode,
  faEllipsisH,
  faLinux,
  faWindows,
  faCheck,
  faArrowLeft,
  faTimes,
  faFrown,
);

const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(store);
app.use(router);
app.mount("#app");
