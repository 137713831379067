<template>
  <div>
    <div
      class="flex p-5 items-center space-x-7 dark:bg-cyan-1200 shadow-md bg-white"
    >
      <!-- <div
        @click="$store.state.menuToggle ? $router.push('/menu') : $router.back()"
        class="flex flex-col justify-center gap-2 cursor-pointer text-3xl"
      >
        <div
          class="w-8 h-0.5 bg-gray-700 dark:bg-gray-300 transition-all duration-500"
          :class="[
            !$store.state.menuToggle ? 'transform rotate-45 translate-y-1.5' : '',
          ]"
        ></div>
        <div
          class="w-8 h-0.5 bg-gray-700 dark:bg-gray-300 transition-all duration-500"
          :class="[
            !$store.state.menuToggle ? 'transform -rotate-45 -translate-y-1' : '',
          ]"
        ></div>
      </div> -->
      <!-- <router-link to="/" class="flex relative group">
        <div
          class="absolute h-3 w-3 bg-cyan-400 rounded-full self-end animate-pulse opacity-80"
        ></div>
        <span class="text-4xl pl-1 dark:text-gray-300 py-2.5">Auraborus</span>
      </router-link> -->
      <router-link
        to="/"
        class="text-4xl font-bold py-2.5 text-gray-700 dark:text-gray-50 focus:outline-none"
        ><span>Aura</span>borus</router-link
      >
      <div
        class="flex-grow gap-3 md:flex hidden pr-28 justify-end relative top-1"
      >
        <router-link
          to="/contact-us"
          :class="[
            'p-2',
            'px-10',
            'border-2',
            'rounded',
            'font-semibold',
            'focus:outline-none',
            'self-start',
            'border-gray-800',
            'hover:bg-gray-800',
            'hover:text-white',
            'dark:border-gray-300',
            'dark:text-gray-50',
            'dark:hover:bg-white',
            'dark:hover:text-cyan-1000',
            'transition-colors',
            'duration-500',
          ]"
          >Request a quote</router-link
        >
        <ThemeSwitch
          class="relative top-2"
          :initialState="$store.state.darkMode"
        />
      </div>

      <!-- Side Nav Button-->
      <div
        class="absolute right-4 mx-3"
        :class="[{ 'z-50': $store.state.menuToggle }]"
      >
        <span class="text-gray-700 dark:text-gray-50">
          <span v-show="!$store.state.menuToggle">Menu</span>
          <span
            class="relative top-1 text-gray-200"
            v-show="$store.state.menuToggle"
            >Close</span
          >
        </span>
        <button
          class="relative top-1 p-2 space-y-2 focus:outline-none"
          @click="toggleNav"
        >
          <div
            class="w-8 h-1 transition-all duration-500"
            :class="[
              $store.state.menuToggle
                ? 'transform rotate-45 translate-y-2 bg-gray-200'
                : 'bg-gray-700 dark:bg-gray-300',
            ]"
          ></div>
          <div
            class="w-8 h-1 transition-all duration-500"
            :class="[
              $store.state.menuToggle
                ? 'transform -rotate-45 -translate-y-1 bg-gray-200'
                : 'bg-gray-700 dark:bg-gray-300',
            ]"
          ></div>
        </button>
      </div>
    </div>
    <!-- Side Nav -->
    <div
      class="h-screen w-full flex fixed top-0 z-10"
      v-show="$store.state.menuToggle"
    >
      <transition name="zoom">
        <div
          v-show="$store.state.menuToggle"
          class="w-1/5 sm:w-3/5 md:w-4/6 lg:w-3/4 bg-cyan-1000 bg-opacity-60"
          @click="toggleNav"
        ></div>
      </transition>

      <transition name="nav-anim">
        <div
          v-show="$store.state.menuToggle"
          class="h-full w-4/5 sm:w-2/5 md:w-2/6 lg:w-1/4 bg-cyan-1200 dark:bg-cyan-1200 text-gray-300 overflow-x-hidden"
        >
          <ThemeSwitch
            :initialState="$store.state.darkMode"
            class="relative top-11 left-5 block md:hidden"
          />

          <div class="grid h-4/5 mt-20">
            <div class="flex flex-col gap-5">
              <router-link
                to="/contact-us"
                :class="[
                  'p-2',
                  'px-10',
                  'border-2',
                  'rounded',
                  'font-semibold',
                  'md:hidden',
                  'self-center',
                  'focus:outline-none',
                  'self-start',
                  'bg-cyan-1000',
                  'border-gray-300',
                  'hover:bg-white',
                  'hover:text-cyan-1000',
                  'transition-colors',
                  'duration-500',
                ]"
              >
                Request A Quote
              </router-link>
              <!-- <div class="justify-self-center space-x-3 block sm:hidden">
                    <div class="inline-block relative bottom-4 font-semibold">Theme: </div>
                    <ThemeSwitch class="inline-block relative top-0.5"/>
                  </div> -->
              <div class="text-lg font-semibold">
                <router-link
                  to="/"
                  class="focus:outline-none block rounded-r-lg p-3"
                >
                  <span class="pl-5 hover:text-white">Home</span>
                </router-link>
                <router-link
                  to="/about-us"
                  class="focus:outline-none block w-1/2 rounded-r-lg p-3"
                >
                  <span class="pl-5 hover:text-white">About Us</span>
                </router-link>

                <div
                  @mouseenter="servicesToggle = true"
                  @mouseleave="servicesToggle = false"
                >
                  <div
                    class="focus:outline-none block cursor-pointer p-3"
                    @click="servicesToggle = !servicesToggle"
                  >
                    <span class="pl-5 text-gray-200">
                      <router-link
                        class="relative bottom-px hover:text-white"
                        to="/services"
                        >Services</router-link
                      >
                      <font-awesome-icon
                        v-show="!servicesToggle"
                        class="ml-3"
                        :icon="['fas', 'caret-right']"
                      />
                      <font-awesome-icon
                        v-show="servicesToggle"
                        class="ml-3"
                        :icon="['fas', 'caret-down']"
                      />
                    </span>
                  </div>
                  <!-- Services Sub Menu -->
                  <transition name="dropdown">
                    <div class="ml-7 mr-5 p-3" v-show="servicesToggle">
                      <router-link
                        class="block my-3 hover:text-white"
                        to="/services/web"
                        >Web App Development</router-link
                      >
                      <router-link
                        class="block my-3 hover:text-white"
                        to="/services/mobile"
                        >Mobile App Development</router-link
                      >
                      <router-link
                        class="block my-3 hover:text-white"
                        to="/services/desktop"
                        >Desktop App Development</router-link
                      >
                    </div>
                  </transition>
                </div>
              </div>
            </div>

            <!-- <div class="absolute bottom-4 bg-gray-200 w-full h-20 rounded-3xl"></div>
                <div
                  class="absolute bottom-4 w-full h-20 rounded-3xl transform bg-gradient-to-br from-cyan-400 to-cyan-600 -rotate-1 sm:-rotate-2"
                ></div> -->
            <div class="self-end flex flex-col items-center space-y-2">
              <div>Email: admin@auraborus.com</div>
              <div class="space-x-3 text-2xl">
                <a
                  href="https://www.linkedin.com/company/auraborus/"
                  target="_blank"
                  class="hover:text-white"
                  ><font-awesome-icon :icon="['fab', 'linkedin']"
                /></a>
                <a
                  href="https://www.facebook.com/auraborusinc"
                  target="_blank"
                  class="hover:text-white"
                  ><font-awesome-icon :icon="['fab', 'facebook-square']"
                /></a>
                <a
                  href="https://twitter.com/AuraborusInc"
                  target="_blank"
                  class="hover:text-white"
                  ><font-awesome-icon :icon="['fab', 'twitter-square']"
                /></a>
              </div>
            </div>
            <div class="text-sm mt-5 w-auto font-semibold justify-self-center">
              &reg;Auraborus 2021
            </div>
          </div>

          <!-- <div class="grid h-96 bg-gray-400 items-end justify-center">
                
              </div>
              -->
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import ThemeSwitch from "./ThemeSwitch";

export default {
  name: "Navbar",
  components: { ThemeSwitch },
  data() {
    return {
      servicesToggle: false,
    };
  },
  methods: {
    toggleNav() {
      this.$store.commit("toggleMenu");
    },
  },
};
</script>

<style scoped>
.shadow-l {
  box-shadow: -3px 0px 30px 0.5px gray;
}

/* Side Nav Animation */
.nav-anim-enter-active {
  transition: 0.2s;
}
.nav-anim-leave-active {
  transition: 0.2s;
}
.nav-anim-enter-from,
.nav-anim-leave-to {
  transform: translateX(200px);
  opacity: 0;
}

/* Drop List Animation */
.dropdown-enter-active,
.dropdown-leave-active {
  transition: all 0.3s;
}
.dropdown-enter-from,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}

/* Fade */
.zoom-enter-active,
.zoom-leave-active {
  transition: all 1.5s;
  transition-delay: 5ms;
}
.zoom-enter-from,
.zoom-leave-to {
  opacity: 0;
}
</style>
